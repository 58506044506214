import { axios, getAuthorization } from '@/api/axios'
import { withRefreshToken } from '@/auth'
import { errConverter } from '../error'

const getPrecheckRegistrantTransfer = (domain_name?: string): Promise<void> =>
  new Promise<void>((resolve, reject) =>
    axios
      .get<void>(`/general-jp/precheck-registrant-transfer`, {
        headers: getAuthorization(),
        params: {
          domain_name,
        },
      })
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(errConverter(err))
      })
  )

const get = withRefreshToken(getPrecheckRegistrantTransfer)

export { get }
