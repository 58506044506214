import React, { useEffect } from 'react'
import { get } from '@/api/gneneral-jp/precheck-registrant-transfer'
import GeneralJp from '@/components/DomainTransfer/GenralJp'
import { useErr, useProgress, useSafeState } from '@/hooks'
import UserInput from '@/components/Parts/UserInput'
import useTranslation from '@/i18n'
import ButtonRounded from '@/components/Parts/ButtonRounded'
import { FaAngleLeft } from 'react-icons/fa'
import { navigate } from 'gatsby'

function DomainTransfer() {
  const [domainName, setDomainName] = useSafeState('')
  const [select, setSelect] = useSafeState('')
  const { t } = useTranslation()
  const { setErr } = useErr()
  const { start, stop } = useProgress()

  const check = () => {
    start(0)
    get(domainName)
      .then(() => {
        setSelect('jp')
        stop()
      })
      .catch((err) => {
        setErr(err.message, err.status)
        setSelect('')
        stop()
      })
  }

  return (
    <>
      <div className="text-gray-700 text-4xl py-2">
        <FaAngleLeft
          className="cursor-pointer"
          onClick={() => navigate('/domain-list')}
        />
      </div>
      <div className="w-full px-2 sm:max-w-screen-md sm:mx-auto">
        <h1 className="mt-4 text-xl text-gray-800 mb-4 font-medium">
          {t('Domain transfer')}
        </h1>
        <UserInput
          name={'Domain'}
          max={253}
          value={domainName}
          onChange={(val) => setDomainName(val.trim())}
          required={false}
          placeholder={'Enter a domain which you want to transfer'}
          invalid={{ message: '' }}
          pattern=".{1,253}"
        />
        <div className="text-right">
          <ButtonRounded
            name="Check"
            onClick={check}
            disabled={domainName.trim().length === 0}
          />
        </div>
        {select === 'jp' && <GeneralJp domainName={domainName} />}
      </div>
    </>
  )
}

export default DomainTransfer
